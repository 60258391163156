<template>
  <div>
    <input
      ref="inputRef"
      class="standalone-input text-right price-input"
      @focus="($refs.inputRef as HTMLInputElement).select()"
      :disabled="disabled"
    />
    <q-tooltip :delay="1000">
      {{ $t("offerPosition.unitPrice") }}
    </q-tooltip>
  </div>
</template>

<script setup lang="ts">
import { useCurrentOrganizationStore } from "@/stores/currentOrganization";
import { storeToRefs } from "pinia";
import { computed, watch } from "vue";
import { useCurrencyInput } from "vue-currency-input";
import { useI18n } from "vue-i18n";

const DEFAULT_CURRENCY = "EUR";

const props = defineProps<{
  modelValue: number | null;
  disabled: boolean;
}>();

defineEmits<{
  "update:value": [value: number | null];
  change: [value: number | null];
}>();

const { locale } = useI18n();
const { organization } = storeToRefs(useCurrentOrganizationStore());

const options = computed(() => ({
  currency: organization.value?.details.currency || DEFAULT_CURRENCY,
  locale: locale.value,
  precision: 2,
}));

const { inputRef, setOptions, setValue } = useCurrencyInput(options.value);

watch(
  () => [organization.value?.details.currency, locale.value],
  () => setOptions(options.value)
);

watch(
  () => props.modelValue,
  (newValue) => {
    setValue(newValue);
  }
);
</script>

<style scoped lang="scss">
.price-input {
  width: 100px;
}
</style>
