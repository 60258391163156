<template>
  <q-avatar
    size="sm"
    :color="backgroundColors[props.status]"
    :text-color="contentColors[props.status]"
    :style="`outline: 1px solid var(--q-${
      contentColors[props.status]
    }) !important`"
  >
    <q-icon :name="icon" size="14px" />
    <slot />
  </q-avatar>
</template>

<script setup lang="ts">
import icons from "@/config/icons";
import type { SupplierRFQStatus } from "@/types/supplierRfq";
import { computed } from "vue";

const props = defineProps<{
  status: SupplierRFQStatus;
}>();

const icon = computed(() => {
  switch (props.status) {
    case "DRAFT":
      return icons.supplierRfqStatus.DRAFT;
    case "SENT":
      return icons.supplierRfqStatus.SENT;
    case "ANSWERED":
      return icons.supplierRfqStatus.ANSWERED;
  }
  throw new Error(`Invalid supplierRfq status: ${props.status}`);
});

const backgroundColors: Record<SupplierRFQStatus, string> = {
  DRAFT: "neutral-2",
  SENT: "primary-1",
  ANSWERED: "positive-1",
};

const contentColors: Record<SupplierRFQStatus, string> = {
  DRAFT: "neutral-7",
  SENT: "primary-5",
  ANSWERED: "positive-6",
};
</script>
