export default {
  supplier: "sym_r_factory",
  supplierRfq: "sym_r_output",
  product: "sym_r_category",
  customer: "sym_r_groups",
  filterStatus: "sym_r_assignment",
  filterUser: "sym_r_person",
  filterInquiry: "sym_r_description",
  filterBuildingProject: "sym_r_apartment",
  filterShortCode: "sym_r_tag",
  filterTags: "sym_r_sell",
  filterSender: "sym_r_alternate_email",
  delete: "sym_r_delete",
  addSupplierRfq: "sym_r_add",
  removeSupplierRfq: "sym_r_remove",
  supplierRfqStatus: {
    DRAFT: "sym_r_draft",
    SENT: "sym_r_output",
    ANSWERED: "sym_r_reply",
    markAsAnswered: "sym_r_check",
    markAsUnanswered: "sym_r_close",
  },
  inquiry: {
    shortCode: "sym_r_tag",
    buildingProject: "sym_r_apartment",
  },
  quotationsPage: "sym_r_sell",
  filter: "sym_r_filter_list",
  inbox: "sym_r_inbox",
  quotation: {
    serialId: "sym_r_tag",
    createdDate: "sym_r_event",
    statusOptions: {
      CREATED: "sym_r_draft",
      ACCEPTED: "sym_r_check_circle",
      DECLINED: "sym_r_close",
    },
    status: "sym_r_assignment",
    acceptedOrDeclinedAt: "sym_r_check_circle",
    totalPriceNet: "sym_r_money",
    positionCount: "sym_r_list",
  },
};
