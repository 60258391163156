<template>
  <q-dialog ref="dialogRef" @hide="onDialogHide">
    <q-card class="q-dialog-plugin">
      <q-card-section class="row justify-between items-center">
        <div class="text-xl">
          {{ $t("supplierRfqsPage.addToSupplierContactsDialog.title") }}
        </div>
        <q-btn flat round dense icon="sym_r_close" @click="onDialogHide" />
      </q-card-section>
      <q-card-section>
        <q-form @submit="addSupplierContact" class="column no-wrap gap-sm">
          <q-input
            autofocus
            dense
            outlined
            v-model="firstName"
            :label="$t('supplierContact.firstName')"
          />
          <q-input
            dense
            outlined
            v-model="lastName"
            :label="$t('supplierContact.lastName')"
          />
          <q-input
            dense
            outlined
            v-model="email"
            :label="$t('supplierContact.email')"
            :rules="['email']"
          />

          <q-banner rounded class="bg-negative text-white q-mb-md" v-if="error">
            {{ error }}
            <template v-slot:action>
              <q-btn
                flat
                color="white"
                :label="$t('Dismiss')"
                @click="error = null"
              />
            </template>
          </q-banner>

          <div class="row justify-end full-width">
            <q-btn dense flat @click="addSupplierContact">
              {{ $t("supplierRfqsPage.addToSupplierContactsDialog.okButton") }}
            </q-btn>
          </div>
        </q-form>
      </q-card-section>
      <q-inner-loading :showing="isLoading" />
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import { useDialogPluginComponent } from "quasar";
import { ref } from "vue";

defineEmits([
  // REQUIRED; need to specify some events that your
  // component will emit through useDialogPluginComponent()
  ...useDialogPluginComponent.emits,
]);

const { dialogRef, onDialogHide, onDialogOK } = useDialogPluginComponent();

const props = defineProps<{
  initialEmail: string;
  supplierId: number;
}>();

const isLoading = ref(false);
const error = ref<string | null>(null);

const firstName = ref("");
const lastName = ref("");
const email = ref(props.initialEmail);

async function addSupplierContact() {
  if (!email.value) return;

  isLoading.value = true;
  error.value = null;

  try {
    // TODO: call backend
    await new Promise((resolve) => setTimeout(resolve, 1000));
    console.log("backend call", firstName.value, lastName.value, email.value);
  } catch (e) {
    error.value = "TODO: ADD ERROR MESSAGE FROM BACKEND";
  } finally {
    isLoading.value = false;
  }

  onDialogOK(
    // TODO: use backend data here that includes the correct id!
    {
      id: 1234,
      firstName: firstName.value,
      lastName: lastName.value,
      address: email.value,
    }
  );
}
</script>
