<template>
  <div class="row items-center justify-end full-width q-py-xs">
    <div class="total-price-string">{{ totalPriceString }}</div>
  </div>
</template>

<script setup lang="ts">
import { useCurrentOrganizationStore } from "@/stores/currentOrganization";
import type { OfferPosition } from "@/types/offerPosition";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const { locale } = useI18n();

const props = defineProps<{
  positions: OfferPosition[];
}>();

const { organization } = storeToRefs(useCurrentOrganizationStore());

const totalPriceString = computed(() => {
  const relevantPositions = props.positions.filter(
    (p) => p.amount && p.unitPrice && !p.isAlternative
  );

  if (relevantPositions.length === 0) {
    return "-";
  }

  const totalPrice = relevantPositions.reduce(
    (acc, p) => acc + (p.amount ?? 0) * (p.unitPrice ?? 0),
    0
  );
  return totalPrice.toLocaleString(locale.value, {
    style: "currency",
    currency: organization.value?.details.currency ?? "EUR",
  });
});
</script>

<style scoped lang="scss">
.total-price-string {
  // align with price display in offer positions
  padding-right: 52px;
}
</style>
