<template>
  <empty-page-placeholder icon="sym_r_notifications">
    <template #text>
      <i18n-t
        keypath="notificationsPage.unreadNotifications"
        tag="div"
        scope="global"
        :plural="unreadCount"
      >
        <template #unreadCount>
          {{ unreadCount }}
        </template>
      </i18n-t>
    </template>
  </empty-page-placeholder>
</template>

<script setup lang="ts">
import EmptyPagePlaceholder from "@/components/EmptyPagePlaceholder.vue";
import { useNotificationsStore } from "@/stores/notifications";
import { storeToRefs } from "pinia";
import { I18nT } from "vue-i18n";

const { unreadCount } = storeToRefs(useNotificationsStore());
</script>
