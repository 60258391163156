<template>
  <q-page class="fit" :style-fn="() => ''">
    <header-bar>
      <master-data-search-bar v-model="searchTerm" class="q-ml-md" />
    </header-bar>
    <master-data-table
      square
      class="col page-below-header-bar"
      :is-loading="isLoading"
      :columns="columns"
      :data="data"
      :page-size="pageSize"
      :page="page"
      :total="total"
      :search-text="searchTerm || ''"
      @next-page="nextPage"
      @previous-page="previousPage"
      @request="handleTableRequest"
    />
  </q-page>
</template>

<script setup lang="ts">
import * as api from "@/api/product";
import HeaderBar from "@/components/Header/HeaderBar.vue";
import MasterDataSearchBar from "@/components/MasterDataPage/MasterDataSearchBar.vue";
import MasterDataTable from "@/components/MasterDataPage/MasterDataTable.vue";
import { useMasterDataPage } from "@/composables/useMasterDataPage";
import { useCurrentOrganizationStore } from "@/stores/currentOrganization";
import type { Product } from "@/types/product";
import { formatCurrencyOrNull, formatTaxRateOrNull } from "@/utils/currency";
import { storeToRefs } from "pinia";
import type { QTableProps } from "quasar";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const { t, locale } = useI18n();

const { organization } = storeToRefs(useCurrentOrganizationStore());

const columns = computed(() => {
  const columns: QTableProps["columns"] = [
    {
      name: "externalId",
      label: t("ID"),
      align: "left",
      field: "externalId",
      sortable: false,
    },
    {
      name: "name",
      label: t("Name"),
      align: "left",
      field: "name",
      sortable: false,
      style:
        "max-width: 400px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;",
    },
    {
      name: "description",
      label: t("Description"),
      align: "left",
      field: "description",
      sortable: false,
      style:
        "max-width: 500px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;",
    },
    {
      name: "unit",
      label: t("Unit"),
      align: "left",
      field: (p: Product) => t(`productUnit.${p.unit}`),
      sortable: false,
    },
    {
      name: "alternative_units",
      label: t("Alternative Units"),
      align: "left",
      field: (p: Product) =>
        p.alternativeUnits.map((unit) => t(`productUnit.${unit}`)).join(", "),
      sortable: false,
    },
  ];
  if (organization.value?.usePrices) {
    columns.push(
      {
        name: "listPrice",
        label: t("product.listPrice"),
        align: "right",
        field: (p: Product) =>
          formatCurrencyOrNull(
            locale.value,
            p.listPrice,
            organization.value?.details.currency ?? null
          ),
        sortable: false,
      },
      {
        name: "vatTaxRate",
        label: t("product.vatTaxRate"),
        align: "right",
        field: (p: Product) => formatTaxRateOrNull(p.vatTaxRate),
        sortable: false,
      }
    );
  }
  return columns;
});

const {
  isLoading,
  data,
  pageSize,
  page,
  total,
  searchTerm,
  nextPage,
  previousPage,
  handleTableRequest,
} = useMasterDataPage(api.listProducts);
</script>
