<template>
  <q-btn
    icon="sym_r_download"
    color="neutral-7"
    flat
    dense
    :disable="isDisabled"
  >
    <q-menu>
      <q-list dense>
        <q-item
          clickable
          v-ripple
          v-close-popup
          @click="downloadInquiryInFormat('offer_excel')"
        >
          <q-item-section>
            {{ $t("downloadInquiryButton.excelOfferFormat") }}
          </q-item-section>
        </q-item>
        <q-item
          clickable
          v-ripple
          v-close-popup
          @click="downloadInquiryInFormat('boq_excel')"
        >
          <q-item-section>
            {{ $t("downloadInquiryButton.excelBOQFormat") }}
          </q-item-section>
        </q-item>
        <q-item
          v-if="isFinalQuotationAvailable"
          clickable
          v-ripple
          v-close-popup
          @click="downloadInquiryInFormat('final_quotation_pdf')"
        >
          <q-item-section>
            {{ $t("downloadInquiryButton.finalQuotationPdfFormat") }}
          </q-item-section>
        </q-item>
      </q-list>
    </q-menu>
    <q-tooltip> {{ $t("downloadInquiryButton.tooltip") }}</q-tooltip>
  </q-btn>
</template>

<script setup lang="ts">
import * as api from "@/api/inquiry";
import { useRouteParams } from "@/composables/useRouteParams";
import { useCurrentInquiryStore } from "@/stores/currentInquiry";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import { useCurrentOrganizationStore } from "@/stores/currentOrganization";

const { organizationId, inboxId, inquiryId } = useRouteParams();

const isDisabled = computed(() =>
  [organizationId.value, inboxId.value, inquiryId.value].some((id) => isNaN(id))
);

const { inquiry } = storeToRefs(useCurrentInquiryStore());
const { organization } = storeToRefs(useCurrentOrganizationStore());

const isFinalQuotationAvailable = computed(() => {
  return (
    organization.value?.useQuotationCreation &&
    inquiry.value &&
    inquiry.value.hasQuotation
  );
});

async function downloadInquiryInFormat(format: string) {
  if (isDisabled.value) return;
  await api.downloadInquiryInFormat(
    organizationId.value,
    inboxId.value,
    inquiryId.value,
    format
  );
}
</script>
