<template>
  <div
    class="text-right total-price-display"
    :class="{ alternative: position.isAlternative }"
  >
    {{ totalPriceString }}
    <q-tooltip :delay="1000">
      {{
        position.isAlternative
          ? t("offerPosition.totalPriceAlternative")
          : t("offerPosition.totalPrice")
      }}
    </q-tooltip>
  </div>
</template>

<script setup lang="ts">
import { useCurrentOrganizationStore } from "@/stores/currentOrganization";
import type { OfferPosition } from "@/types/offerPosition";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const { locale, t } = useI18n();

const props = defineProps<{
  position: OfferPosition;
}>();

const { organization } = storeToRefs(useCurrentOrganizationStore());

const totalPriceString = computed(() => {
  if (!props.position.amount || !props.position.unitPrice) {
    return "-";
  }

  const totalPrice = props.position.amount * props.position.unitPrice;
  const priceString = totalPrice.toLocaleString(locale.value, {
    style: "currency",
    currency: organization.value?.details.currency ?? "EUR",
  });
  return props.position.isAlternative ? `* ${priceString}` : priceString;
});
</script>

<style scoped lang="scss">
.total-price-display {
  padding-right: 3px; /* align with price input */
  width: 100px;

  &.alternative {
    color: $neutral-7;
  }
}
</style>
