<template>
  <q-breadcrumbs class="header-bar-breadcrumbs">
    <q-breadcrumbs-el
      v-for="crumb in crumbs"
      :key="crumb.label"
      :label="crumb.label"
      :to="crumb.to"
      :class="{
        'text-neutral-10': crumb.to !== undefined,
        'text-neutral-7': crumb.to === undefined,
      }"
    />
  </q-breadcrumbs>
</template>

<script setup lang="ts">
import { useRouteParams } from "@/composables/useRouteParams";
import { useCurrentInboxStore } from "@/stores/currentInbox";
import { useCurrentInquiryStore } from "@/stores/currentInquiry";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

const { inboxView, inquiryId } = useRouteParams();
const { inbox } = storeToRefs(useCurrentInboxStore());
const { inquiry } = storeToRefs(useCurrentInquiryStore());
const { t } = useI18n();
const { currentRoute } = useRouter();

const crumbs = computed(() => {
  const crumbs = [];

  if (currentRoute.value.name == "quotations") {
    crumbs.push({
      label: t("quotationsPage.title"),
    });
    return crumbs;
  }

  if (currentRoute.value.name?.toString().startsWith("supplier-rfqs")) {
    crumbs.push({
      label: t("supplierRfqsPage.title"),
    });

    return crumbs;
  }

  if (currentRoute.value.name == "products") {
    crumbs.push({
      label: t("Products"),
    });
    return crumbs;
  }

  if (currentRoute.value.name == "customers") {
    crumbs.push({
      label: t("Customers"),
    });
    return crumbs;
  }

  if (currentRoute.value.name == "suppliers") {
    crumbs.push({
      label: t("Suppliers"),
    });
    return crumbs;
  }

  if (currentRoute.value.name == "account-settings") {
    crumbs.push({
      label: t("Account settings"),
    });
    return crumbs;
  }

  if (currentRoute.value.name == "organization-settings") {
    crumbs.push({
      label: t("Organization settings"),
    });
    return crumbs;
  }

  if (currentRoute.value.name?.toString().includes("notifications")) {
    crumbs.push({
      label: t("notificationsPage.label"),
    });
    return crumbs;
  }

  if (inbox.value !== null && inboxView.value != undefined) {
    crumbs.push({
      label: inbox.value.name + " - " + t(`inboxView_${inboxView.value}`),
      to: isNaN(inquiryId.value)
        ? undefined
        : {
            name: "inbox",
            params: {
              inboxView: inboxView.value,
            },
          },
    });
  }

  if (!isNaN(inquiryId.value)) {
    crumbs.push({
      label: inquiry.value?.shortCode,
      to:
        currentRoute.value.name == "inquiry" ? undefined : { name: "inquiry" },
    });

    if (currentRoute.value.name == "inquiry-positions") {
      crumbs.push({
        label: t("Positions"),
      });
    }

    if (
      currentRoute.value.name?.toString().startsWith("inquiry-supplier-rfqs")
    ) {
      crumbs.push({
        label: t("supplierRfqsPage.title"),
      });
    }
  }

  return crumbs;
});
</script>

<style lang="scss">
.header-bar-breadcrumbs {
  overflow-x: hidden;
  flex-shrink: 1;

  > div {
    flex-wrap: nowrap;
    > div {
      white-space: nowrap;
    }
  }
}
</style>
