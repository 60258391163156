import { inject, provide } from "vue";

const POSITION_REF_KEY = Symbol("scrollers");

type RegisterScrollerFn = (referenceId: string, scroller: () => void) => void;

export function provideGaebScrollToHighlight() {
  let scrollers: Record<string, () => void> = {};

  function scrollToHighlight(referenceId: string) {
    const scroller = scrollers[referenceId];
    if (scroller) {
      scroller();
    }
  }

  function clearScrollers() {
    scrollers = {};
  }

  function registerScroller(referenceId: string, scroller: () => void) {
    scrollers[referenceId] = scroller;
  }

  provide<RegisterScrollerFn>(POSITION_REF_KEY, registerScroller);

  return {
    scrollers,
    scrollToHighlight,
    clearScrollers,
    registerScroller,
  };
}

export function useGaebScrollToHighlight() {
  const registerScroller = inject<RegisterScrollerFn>(POSITION_REF_KEY);
  if (!registerScroller) {
    throw new Error(
      "useGaebScrollToHighlight must be used within a provideGaebScrollToHighlight"
    );
  }
  return registerScroller;
}
