<template>
  <q-btn flat dense :disable="isDisabled" @click.left="handleClick">
    <SupplierRfqStatusIcon :status="props.status">
      <q-tooltip>{{ $t(`supplierRfqStatus.${status}`) }}</q-tooltip>
    </SupplierRfqStatusIcon>
    <q-menu v-if="props.status !== 'DRAFT'">
      <q-list dense>
        <q-item
          clickable
          v-close-popup
          v-if="props.status === 'SENT'"
          @click="$emit('markAsAnswered')"
        >
          <q-item-section>
            <q-item-label>
              <SupplierRfqStatusIcon status="ANSWERED" class="q-mr-sm" />
              {{ $t(`supplierRfqsPage.markAsAnswered`) }}
            </q-item-label>
          </q-item-section>
        </q-item>
        <q-item
          clickable
          v-close-popup
          v-if="props.status === 'ANSWERED'"
          @click="$emit('markAsUnanswered')"
        >
          <q-item-section>
            <q-item-label>
              <SupplierRfqStatusIcon status="SENT" class="q-mr-sm" />
              {{ $t(`supplierRfqsPage.markAsUnanswered`) }}
            </q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
    </q-menu>
  </q-btn>
</template>

<script setup lang="ts">
import type { SupplierRFQStatus } from "@/types/supplierRfq";
import { computed } from "vue";
import SupplierRfqStatusIcon from "./SupplierRfqStatusIcon.vue";

const props = defineProps<{
  status: SupplierRFQStatus;
}>();

defineEmits(["markAsAnswered", "markAsUnanswered"]);

const isDisabled = computed(() => props.status === "DRAFT");

function handleClick(event: Event) {
  if (isDisabled.value) return;
  event.preventDefault();
  event.stopPropagation();
}
</script>
