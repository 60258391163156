<template>
  <empty-page-placeholder :icon="icons.supplierRfq">
    <template #text>
      <i18n-t
        keypath="supplierRfqsPage.numSupplierRfqs"
        tag="div"
        scope="global"
        :plural="supplierRfqs.length"
      >
        <template #numSupplierRfqs>
          {{ supplierRfqs.length }}
        </template>
      </i18n-t>
    </template>
  </empty-page-placeholder>
</template>

<script setup lang="ts">
import EmptyPagePlaceholder from "@/components/EmptyPagePlaceholder.vue";
import icons from "@/config/icons";
import { useCurrentSupplierRfqsStore } from "@/stores/currentSupplierRfqs";
import { storeToRefs } from "pinia";
import { I18nT } from "vue-i18n";

const { supplierRfqs } = storeToRefs(useCurrentSupplierRfqsStore());
</script>
