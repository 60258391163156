<template>
  <q-drawer
    :model-value="true"
    side="left"
    behavior="desktop"
    class="flex column no-wrap text-neutral-9 bg-neutral-2"
    :width="220"
  >
    <div class="row no-wrap items-center q-pt-md q-px-sm full-width">
      <q-btn
        flat
        dense
        icon="sym_r_arrow_left_alt"
        @click="$router.push(closeSettingsTarget)"
        class="q-mr-sm"
      />
      <div class="text-subtitle1">{{ $t("Settings") }}</div>
    </div>
    <div class="column sidebar-menu q-px-sm full-width">
      <q-list dense class="full-width">
        <sidebar-header icon="sym_r_domain" label="Organization" />
        <sidebar-item
          label="settings.organization.general.label"
          :to="{ name: 'settings-organization-general' }"
        />
        <sidebar-item
          v-if="canEditOrganizationUsers"
          label="settings.organization.members.label"
          :to="{ name: 'settings-organization-members' }"
        />
        <sidebar-item
          label="settings.organization.positionDetection.label"
          :to="{ name: 'settings-organization-position-detection' }"
        />
        <sidebar-item
          label="settings.organization.supplierTypeDetection.label"
          :to="{ name: 'settings-organization-supplier-type-detection' }"
        />
        <sidebar-item
          v-if="canEditOrganizationDetails"
          label="settings.organization.companyInformation.label"
          :to="{ name: 'settings-organization-company-information' }"
        />
        <sidebar-item
          label="settings.organization.exportConfiguration.label"
          :to="{ name: 'settings-organization-export-configuration' }"
          v-if="canEditExportConfiguration"
        />
        <sidebar-item
          label="settings.organization.productImportConfiguration.label"
          :to="{ name: 'settings-organization-product-import' }"
          v-if="canEditProductImportConfiguration"
        />
        <sidebar-item
          label="settings.organization.customerImportConfiguration.label"
          :to="{ name: 'settings-organization-customer-import' }"
          v-if="canEditCustomerImportConfiguration"
        />
        <sidebar-item
          label="settings.organization.usageDashboard.label"
          :to="{ name: 'settings-organization-usage-dashboard' }"
          v-if="canSeeUsageDashboard"
        />
      </q-list>
      <q-list dense class="full-width">
        <sidebar-header icon="sym_r_inbox" label="Inboxes" />
        <div class="column no-wrap gap-xs">
          <template v-for="inbox in inboxes" :key="inbox.id">
            <sidebar-inbox-item :inbox="inbox" />
          </template>
          <sidebar-add-inbox-item v-if="canCreateNewInbox" />
        </div>
      </q-list>
      <q-list dense>
        <sidebar-header icon="sym_r_person" label="Account" />
        <sidebar-item
          label="settings.account.profile.label"
          :to="{ name: 'settings-account-profile' }"
        />
        <sidebar-item
          label="settings.account.access.label"
          :to="{ name: 'settings-account-access' }"
        />
        <sidebar-item
          label="settings.account.notifications.label"
          :to="{ name: 'settings-account-notifications' }"
        />
        <sidebar-item
          v-if="organization?.useSupplierData"
          label="settings.account.emailSending.label"
          :to="{ name: 'settings-account-email-sending' }"
        />
      </q-list>
      <div class="changelog-widget-spacer"></div>
    </div>
  </q-drawer>
</template>

<script setup lang="ts">
import SidebarAddInboxItem from "@/components/Settings/SidebarAddInboxItem.vue";
import SidebarHeader from "@/components/Settings/SidebarHeader.vue";
import SidebarInboxItem from "@/components/Settings/SidebarInboxItem.vue";
import SidebarItem from "@/components/Settings/SidebarItem.vue";
import { LAST_ROUTE_BEFORE_SETTINGS } from "@/config/symbols";
import { useCurrentOrganizationStore } from "@/stores/currentOrganization";
import { useCurrentPermissionsStore } from "@/stores/currentPermissions";
import { useInboxesStore } from "@/stores/inboxes";
import { storeToRefs } from "pinia";
import { computed } from "vue";

const { inboxes } = storeToRefs(useInboxesStore());
const { organization } = storeToRefs(useCurrentOrganizationStore());
const { hasCurrentOrganizationRole } = storeToRefs(
  useCurrentPermissionsStore()
);

const canEditOrganizationUsers = computed(
  () => hasCurrentOrganizationRole.value["organization_admin"]
);
const canEditExportConfiguration = computed(
  () => hasCurrentOrganizationRole.value["organization_admin"]
);
const canCreateNewInbox = computed(
  () => hasCurrentOrganizationRole.value["organization_admin"]
);
const canEditProductImportConfiguration = computed(
  () => hasCurrentOrganizationRole.value["organization_admin"]
);
const canSeeUsageDashboard = computed(
  () => hasCurrentOrganizationRole.value["organization_admin"]
);
const canEditCustomerImportConfiguration = computed(
  () =>
    hasCurrentOrganizationRole.value["organization_admin"] &&
    organization.value?.useCustomerData
);
const canEditOrganizationDetails = computed(
  () =>
    hasCurrentOrganizationRole.value["organization_admin"] &&
    organization.value?.useQuotationCreation
);

const closeSettingsTarget = sessionStorage.getItem(
  LAST_ROUTE_BEFORE_SETTINGS
) || { name: "organization" };
</script>

<style scoped lang="scss">
.changelog-widget-spacer {
  height: 60px;
  flex-shrink: 0;
  flex-grow: 0;
}
</style>
