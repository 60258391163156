import NoNotificationSelected from "@/components/NoNotificationSelected.vue";
import NoSupplierRfqSelected from "@/components/NoSupplierRfqSelected.vue";
import { LAST_ROUTE_BEFORE_SETTINGS } from "@/config/symbols";
import LoggedInLayout from "@/layouts/LoggedInLayout.vue";
import PlainLayout from "@/layouts/PlainLayout.vue";
import SettingsLayout from "@/layouts/SettingsLayout.vue";
import AcceptInvitationPage from "@/pages/AcceptInvitationPage.vue";
import CurrentSupplierRfqPage from "@/pages/CurrentSupplierRfqPage.vue";
import CustomersPage from "@/pages/CustomersPage.vue";
import InboxPage from "@/pages/InboxPage.vue";
import InquiryPage from "@/pages/InquiryPage.vue";
import InquiryPageRight from "@/pages/InquiryPageRight.vue";
import InquiryPositionsPage from "@/pages/InquiryPositionsPage.vue";
import LoginPage from "@/pages/LoginPage.vue";
import NotificationsPage from "@/pages/NotificationsPage.vue";
import OrganizationRootPage from "@/pages/OrganizationRootPage.vue";
import PasswordResetPage from "@/pages/PasswordResetPage.vue";
import ProductsPage from "@/pages/ProductsPage.vue";
import QuotationsPage from "@/pages/QuotationsPage.vue";
import RootRedirect from "@/pages/RootRedirect.vue";
import SettingsAccountAccessPage from "@/pages/Settings/Account/AccessSettings.vue";
import SettingsAccountEmailSendingPage from "@/pages/Settings/Account/EmailSending.vue";
import SettingsAccountNotificationsPage from "@/pages/Settings/Account/NotificationsSettings.vue";
import SettingsAccountProfilePage from "@/pages/Settings/Account/ProfileSettings.vue";
import SettingsInboxCustomStatusesPage from "@/pages/Settings/Inbox/CustomStatusesSettings.vue";
import SettingsInboxEmailPage from "@/pages/Settings/Inbox/EmailSettings.vue";
import SettingsInboxGeneralPage from "@/pages/Settings/Inbox/GeneralSettings.vue";
import SettingsInboxMembersPage from "@/pages/Settings/Inbox/MembersSettings.vue";
import SettingsInboxTagsPage from "@/pages/Settings/Inbox/TagsSettings.vue";
import SettingsOrganizationCompanyInformationPage from "@/pages/Settings/Organization/CompanyInformationSettings.vue";
import SettingsOrganizationCustomerImport from "@/pages/Settings/Organization/CustomerImport.vue";
import SettingsOrganizationExportConfigurationPage from "@/pages/Settings/Organization/ExportConfiguration.vue";
import SettingsOrganizationGeneralPage from "@/pages/Settings/Organization/GeneralSettings.vue";
import SettingsOrganizationMembersPage from "@/pages/Settings/Organization/MembersSettings.vue";
import SettingsOrganizationPositionDetectionPage from "@/pages/Settings/Organization/PositionDetectionSettings.vue";
import SettingsOrganizationProductImport from "@/pages/Settings/Organization/ProductImport.vue";
import SettingsOrganizationSupplierTypeDetectionPage from "@/pages/Settings/Organization/SupplierTypeDetectionSettings.vue";
import SettingsOrganizationUsageDashboardPage from "@/pages/Settings/Organization/UsageDashboard.vue";
import SupplierRfqsPage from "@/pages/SupplierRfqsPage.vue";
import SuppliersPage from "@/pages/SuppliersPage.vue";
import type { RouteLocationNormalized } from "vue-router";

export const routes = [
  {
    path: "/",
    component: RootRedirect,
  },
  {
    path: "/login",
    component: PlainLayout,
    children: [
      {
        path: "",
        name: "login",
        component: LoginPage,
      },
    ],
  },
  {
    path: "/password-reset-confirm/",
    component: PlainLayout,
    children: [
      {
        path: "",
        name: "password-reset-confirm",
        component: PasswordResetPage,
      },
    ],
  },
  {
    path: "/signup/",
    component: PlainLayout,
    children: [
      {
        path: "",
        name: "signup",
        component: AcceptInvitationPage,
      },
    ],
  },
  {
    path: "/organizations/:organizationId/settings",
    component: SettingsLayout,
    beforeEnter: (
      to: RouteLocationNormalized,
      from: RouteLocationNormalized
    ) => {
      sessionStorage.setItem(LAST_ROUTE_BEFORE_SETTINGS, from.fullPath);
    },
    children: [
      {
        path: "organization",
        children: [
          {
            path: "general",
            name: "settings-organization-general",
            component: SettingsOrganizationGeneralPage,
          },
          {
            path: "members",
            name: "settings-organization-members",
            component: SettingsOrganizationMembersPage,
          },
          {
            path: "position-detection",
            name: "settings-organization-position-detection",
            component: SettingsOrganizationPositionDetectionPage,
          },
          {
            path: "supplier-type-detection",
            name: "settings-organization-supplier-type-detection",
            component: SettingsOrganizationSupplierTypeDetectionPage,
          },
          {
            path: "company-information",
            name: "settings-organization-company-information",
            component: SettingsOrganizationCompanyInformationPage,
          },
          {
            path: "export-configuration",
            name: "settings-organization-export-configuration",
            component: SettingsOrganizationExportConfigurationPage,
          },
          {
            path: "product-import",
            name: "settings-organization-product-import",
            component: SettingsOrganizationProductImport,
          },
          {
            path: "customer-import",
            name: "settings-organization-customer-import",
            component: SettingsOrganizationCustomerImport,
          },
          {
            path: "usage-dashboard",
            name: "settings-organization-usage-dashboard",
            component: SettingsOrganizationUsageDashboardPage,
          },
        ],
      },
      {
        path: "inbox/:inboxId",
        children: [
          {
            path: "general",
            name: "settings-inbox-general",
            component: SettingsInboxGeneralPage,
          },
          {
            path: "email",
            name: "settings-inbox-email",
            component: SettingsInboxEmailPage,
          },
          {
            path: "members",
            name: "settings-inbox-members",
            component: SettingsInboxMembersPage,
          },
          {
            path: "tags",
            name: "settings-inbox-tags",
            component: SettingsInboxTagsPage,
          },
          {
            path: "custom-statuses",
            name: "settings-inbox-custom-statuses",
            component: SettingsInboxCustomStatusesPage,
          },
        ],
      },
      {
        path: "account",
        children: [
          {
            path: "profile",
            name: "settings-account-profile",
            component: SettingsAccountProfilePage,
          },
          {
            path: "access",
            name: "settings-account-access",
            component: SettingsAccountAccessPage,
          },
          {
            path: "notifications",
            name: "settings-account-notifications",
            component: SettingsAccountNotificationsPage,
          },
          {
            path: "email-sending",
            name: "settings-account-email-sending",
            component: SettingsAccountEmailSendingPage,
          },
        ],
      },
    ],
  },
  {
    path: "/organizations/:organizationId/",
    component: LoggedInLayout,
    children: [
      {
        path: "",
        name: "organization",
        component: OrganizationRootPage,
      },
      {
        path: "quotations/",
        name: "quotations",
        component: QuotationsPage,
      },
      {
        path: "inboxes/:inboxId/:inboxView/",
        name: "inbox",
        component: InboxPage,
      },
      {
        path: "inboxes/:inboxId/:inboxView/inquiries/:inquiryId/",
        name: "inquiry",
        component: InquiryPage,
      },
      {
        path: "inboxes/:inboxId/:inboxView/inquiries/:inquiryId/positions/",
        name: "inquiry-positions",
        component: InquiryPositionsPage,
      },
      {
        path: "inboxes/:inboxId/:inboxView/inquiries/:inquiryId/supplier-rfqs/",
        component: SupplierRfqsPage,
        children: [
          {
            path: "",
            name: "inquiry-supplier-rfqs",
            component: NoSupplierRfqSelected,
          },
          {
            path: ":supplierRfqId/",
            name: "inquiry-supplier-rfqs.current-supplier-rfq",
            component: CurrentSupplierRfqPage,
          },
        ],
      },
      {
        path: "supplier-rfqs/",
        component: SupplierRfqsPage,
        children: [
          {
            path: "",
            name: "supplier-rfqs",
            component: NoSupplierRfqSelected,
          },
          {
            path: ":supplierRfqId/",
            name: "supplier-rfqs.current-supplier-rfq",
            component: CurrentSupplierRfqPage,
          },
        ],
      },
      {
        path: "products/",
        name: "products",
        component: ProductsPage,
      },
      {
        path: "customers/",
        name: "customers",
        component: CustomersPage,
      },
      {
        path: "suppliers/",
        name: "suppliers",
        component: SuppliersPage,
      },
      {
        path: "notifications/",
        component: NotificationsPage,
        children: [
          {
            path: "",
            name: "notifications",
            components: {
              right: NoNotificationSelected,
            },
          },
          {
            path: ":notificationId/inboxes/:inboxId/:inboxView/inquiries/:inquiryId/",
            name: "notification-details",
            components: {
              right: InquiryPageRight,
            },
          },
        ],
      },
    ],
  },
];
